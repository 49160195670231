import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CONTACT_PATH } from '../../../../constants';
import { INVOICES_TAB } from '../../../../constants/navigation';
import {
  BILLING_VARIABLE,
  PREVIEW_CONTENT_TYPE,
} from '../../../../constants/textEditor';
import { AppContext } from '../../../../contexts/AppContext';
import { NotificationService } from '../../../../services/NotificationService';
import Anchor from '../../../atoms/anchors/Anchor';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import SelectWithValues from '../../../atoms/selects/SelectWithValues';
import Span from '../../../atoms/spans/Span';
import Alert from '../../../molecules/alerts/Alert';
import { RequestHandelingAlert } from '../../../organisms/alerts/RequestHandelingAlert';
import { buildPath } from '../../../organisms/containers/util/pathHelper';
import SearchByClient from '../../../organisms/dropdowns/SearchByClient';
import TextEditor from '../../../organisms/editor/TextEditor';
import EditorPreview from '../../../organisms/editor/previews/EditorPreview';
import SuccessModal from '../../feedback/modal/SuccessModal';
import { GlobalsHelpers } from '../../helper/globals';
import { fetchClients } from '../../invoices/util/api/request';
import { BillingAlertsHelper } from './helper';
import Menu, { MENU_CATEGORY } from '../../../organisms/menu/Menu';
import Button from '../../../atoms/buttons/Button';
import NotAllowedLayout from './NotAllowedLayout';
import CheckBoxLabel from '../../../molecules/labels/CheckBoxLabel';

const BillingAlertsLayout = (props) => {
  const { appState } = useContext(AppContext);
  const defaultClient = BillingAlertsHelper.getDefaultClientOption(props.intl);
  const defaultFormData = BillingAlertsHelper.getInitialState(props.intl);

  const emailTemplate =
    props.intl.messages['billingTemplateContentEmail'] +
    props.intl.messages['billingTemplateFixedEmail'];

  const [form, setForm] = useState(defaultFormData);
  const [byDefaultClientForm, setByDefaultClientForm] =
    useState(defaultFormData);
  const [clientSelected, setClientSelected] = useState(defaultClient);
  const [clientList, setClientList] = useState(defaultClient);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [requestErrors, setRequestErrors] = useState('');
  const [hasTemplate, setHasTemplate] = useState(false);
  const [applyDefaultSingle, setApplyDefaultSingle] = useState(false);
  const [applyDefaultAll, setApplyDefaultAll] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const useDefaultSingle = false;
  const useDefaultAll = false;

  /**
   * Update form values
   *
   * @param {string} name - column name
   * @param {string} value
   */
  const handleFields = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
   * Verify if field should be disabled
   *
   * @returns {boolean}
   */
  const shouldFieldBeDisabled = () => {
    return (
      form?.frequency === GlobalsHelpers.FREQUENCY_TYPE.NOT_SEND ||
      (applyDefaultSingle && useDefaultSingle)
    );
  };

  /**
   * Mount header
   * @param {string} key - key to translation
   */
  const mountHeaderOfSection = (key) => {
    return (
      <div className='column px-0 mt-4'>
        <Paragraph className='text-header h4 mb-0'>
          {props.intl.messages[`${key}Title`]}
        </Paragraph>
        <div className='sep-light mt-1' />
      </div>
    );
  };

  /**
   * Mount editor
   * @param {string} type - type of preview (sms/email)
   */
  const mountTextEditorWithPreview = (type) => {
    const previewText = form?.email_template
      ? form?.email_template + props.intl.messages['billingTemplateFixedEmail']
      : form?.email_template || emailTemplate;

    return (
      <div key={type} className='row px-0 mx-0 mt-4'>
        <div className='col-md-6 ps-0'>
          <TextEditor
            defaultValue={
              form?.email_template ||
              props.intl.messages['billingTemplateContentEmail']
            }
            onChange={(value) => {
              handleFields(`${type}_template`, value);
            }}
            variables={BILLING_VARIABLE}
          />
        </div>
        <div className='col-md-6 pe-0'>
          <EditorPreview
            type={PREVIEW_CONTENT_TYPE[type.toUpperCase()]}
            template={previewText}
            accountLogo={props.template?.email?.logo_url || ''}
          />
          <Paragraph>
            {props.intl.messages['templateVariableMessage']}
          </Paragraph>
        </div>
      </div>
    );
  };

  /**
   * Verify required fields
   * @returns {boolean}
   */
  const isRequiredFieldsFilled = () => {
    if (form?.frequency === GlobalsHelpers.FREQUENCY_TYPE.NOT_SEND) {
      return true;
    }

    return Object.keys(form).length > 5;
  };

  const onSubmit = async () => {
    setIsSaveDisabled(true);

    const response = hasTemplate
      ? await NotificationService.updateBillingAlertConfiguration(form)
      : await NotificationService.createBillingAlertConfiguration(form);

    if (!response.error) {
      setIsSubmitted(true);
      setRequestErrors('');
      setIsSaveDisabled(false);
      resetState(clientSelected);
      return;
    } else {
      setRequestErrors(response?.error);
    }
  };

  /**
   * Set the selected client object
   * @param {string} id - client_id
   */
  const handleDropdownSelection = (id, reset = false) => {
    if (reset) {
      searchClientByTerm('...');
    }

    const selected = clientList.filter((client) => client.id === id);
    handleFields('client_id', id);
    setClientSelected(selected);
  };

  /**
   * Fetch a list of client by name
   * @param {string} search - client name
   */
  const searchClientByTerm = async (search) => {
    setSearchTerm(search);

    if (search.length >= 3) {
      const response = await fetchClients(
        appState.accountId,
        search === '...' ? '' : search,
        INVOICES_TAB
      );

      if (search === '...') {
        setSearchTerm('');
      } else {
        setSearchTerm(search);
      }

      if (!response.error) {
        setClientList([...defaultClient, ...response.clients]);
      }
    }
  };

  /**
   * Reset state
   * @param {object} selectedClient - client selected
   * @returns {boolean}
   */
  const resetState = (selectedClient) => {
    setClientSelected(selectedClient);
    searchClientByTerm('...');
  };

  const onChangeApplyDefaultSingle = () => {
    setForm({
      ...byDefaultClientForm,
      client_id: form?.client_id,
      replicate: !applyDefaultSingle,
    });
    setApplyDefaultSingle(!applyDefaultSingle);
  };

  const onChangeApplyDefaultAll = () => {
    setForm({ ...form, replicate: !applyDefaultAll });
    setApplyDefaultAll(!applyDefaultAll);
  };

  useEffect(() => {
    const fetchConfiguration = async () => {
      const response = await NotificationService.getBillingAlertConfiguration(
        form?.client_id
      );
      if (form?.client_id === '') {
        setByDefaultClientForm({
          ...response.billing_alert,
          client_has_email: defaultFormData.client_has_email,
          client_id: form?.client_id,
          frequency:
            response.billing_alert?.frequency || defaultFormData.frequency,
          due: response.billing_alert?.due || defaultFormData.due,
          weekday: response.billing_alert?.weekday || defaultFormData.weekday,
          period: response.billing_alert?.period || defaultFormData.period,
        });

        setApplyDefaultAll(false);
      } else {
        if (form?.configurable === undefined) {
          setApplyDefaultSingle(true);
        } else {
          setApplyDefaultSingle(false);
        }
      }

      if (!response.error && response.billing_alert) {
        setForm({
          ...response.billing_alert,
          client_id: form?.client_id,
          frequency:
            response.billing_alert?.frequency || defaultFormData.frequency,
          due: response.billing_alert?.due || defaultFormData.due,
          weekday: response.billing_alert?.weekday || defaultFormData.weekday,
          period: response.billing_alert?.period || defaultFormData.period,
        });
        setHasTemplate(response.billing_alert?.frequency ? true : false);
        return;
      }
      setForm({ ...defaultFormData, client_id: form?.client_id });
      setHasTemplate(false);
    };

    fetchConfiguration();
    searchClientByTerm('...');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form?.client_id]);

  return appState.PlanHasBillingAlerts === 'true' ? (
    <div id='main-content' className='container --billing-alerts'>
      <div className='row px-0 mx-0 justify-content-center'>
        <div className='col-11 col-lg-9 ps-0'>
          <div className='container px-0 mx-0'>
            <div className='row px-0 mx-0 content-block advanced-search-block'>
              <div className='row px-0 mx-0 title-block'>
                <div className='col-12 px-0'>
                  <Paragraph className='text-header h2'>
                    {props.intl.messages['billingAlertsTitle']}
                  </Paragraph>

                  <Paragraph className='text-paragraph mt-3 mb-2'>
                    <Span className='d-block'>
                      {props.intl.messages['billingAlertsDescription']}
                    </Span>
                  </Paragraph>

                  {requestErrors.length > 0 && (
                    <RequestHandelingAlert error={requestErrors} />
                  )}
                </div>
              </div>
            </div>

            <div className='form-container'>
              <div className='row px-0 mx-0 '>
                {!form.client_has_email && (
                  <div className='col-12 px-0 mb-4'>
                    <Alert
                      id='regularization-information-message'
                      alertType={`alert-warning --icon mt-3`}
                      iconClassName='fas fa-exclamation-circle'
                    >
                      <FormattedMessage
                        id={'userHasNoEmail'}
                        key={'client-alert'}
                        values={{
                          a: (chunks) => (
                            <Anchor
                              className='d-block bold color-brand-primary'
                              href={buildPath(
                                CONTACT_PATH(form.client_id).EDIT_CONTACT
                              )}
                              key={'client-alert'}
                              pathKey={'client-alert'}
                            >
                              {chunks}
                            </Anchor>
                          ),
                        }}
                      />
                    </Alert>
                  </div>
                )}

                <div className='col-lg-6 col-xs-12 position-relative px-0'>
                  <SearchByClient
                    withoutCheckbox
                    clientSearchTerm={searchTerm}
                    clients={clientList}
                    clientsToFilterDocs={[clientSelected[0]?.id]}
                    clientNameSelected={[clientSelected[0]?.name]}
                    getClientsMatch={searchClientByTerm}
                    getDocumentsByClient={() => {}}
                    onSelectRadio={handleDropdownSelection}
                    onResetFilter={handleDropdownSelection}
                  />
                </div>
              </div>

              {/* Starts frequency section */}
              {mountHeaderOfSection('billingFrequencySection')}
              <div className='row px-0 mx-0 mt-4'>
                {form?.client_id === '' && useDefaultAll && (
                  <CheckBoxLabel
                    className='checkbox mt-0 mb-4'
                    onChange={onChangeApplyDefaultAll}
                    checked={applyDefaultAll}
                  >
                    <span className='ms-2'>
                      {props.intl.messages['billingAlertsApplyDefaultAll']}
                    </span>
                  </CheckBoxLabel>
                )}

                {form?.client_id !== '' && useDefaultSingle && (
                  <CheckBoxLabel
                    className='checkbox mt-0 mb-4'
                    onChange={onChangeApplyDefaultSingle}
                    checked={applyDefaultSingle}
                  >
                    <span className='ms-2'>
                      {props.intl.messages['billingAlertsApplyDefaultSingle']}
                    </span>
                  </CheckBoxLabel>
                )}

                <div className='col-lg-6 col-xs-12 ps-0'>
                  <label className='text-label mb-2'>
                    {props.intl.messages['billingFrequencyStartLabel']}
                  </label>
                  <SelectWithValues
                    id='before_overdue'
                    key='due'
                    valueName='value'
                    options={GlobalsHelpers.getTypeOfFrequencyList(props.intl)}
                    value={
                      form.due ||
                      props.intl.messages[
                        GlobalsHelpers.SCHEDULE_WHEN.AFTER_OVERDUE
                      ]
                    }
                    onChange={(e) => handleFields('due', e.target.value)}
                    disabled={shouldFieldBeDisabled()}
                  />
                </div>

                <div className='col-lg-6 col-xs-12 pe-0'>
                  <label className='text-label mb-2'>
                    {props.intl.messages['billingFrequencyLabel']}
                  </label>
                  <SelectWithValues
                    id='biweekly'
                    key='frequency'
                    valueName='value'
                    options={GlobalsHelpers.getFrequencyOfAlertList(props.intl)}
                    value={
                      form?.frequency ||
                      props.intl.messages[
                        GlobalsHelpers.FREQUENCY_TYPE.NOT_SEND
                      ]
                    }
                    onChange={(e) => handleFields('frequency', e.target.value)}
                    disabled={applyDefaultSingle && useDefaultSingle}
                  />
                </div>
              </div>

              <div className='row px-0 mx-0 mt-4'>
                <div className='col-lg-6 col-xs-12 ps-0'>
                  <label className='text-label'>
                    {props.intl.messages['billingFrequencyWeekDayLabel']}
                  </label>
                  <SelectWithValues
                    id='weekday'
                    key='weekday'
                    valueName='value'
                    options={GlobalsHelpers.getDaysOfWeekList(props.intl)}
                    value={form?.weekday || props.intl.messages['monday']}
                    onChange={(e) => handleFields('weekday', e.target.value)}
                    disabled={shouldFieldBeDisabled()}
                  />
                </div>

                <div className='col-lg-6 col-xs-12 pe-0'>
                  <label className='text-label'>
                    {props.intl.messages['billingPeriodLabel']}
                  </label>
                  <SelectWithValues
                    id='period'
                    key='period'
                    valueName='value'
                    options={GlobalsHelpers.getWorkPeriodList(props.intl)}
                    value={form?.period || props.intl.messages['morning']}
                    onChange={(e) => handleFields('period', e.target.value)}
                    disabled={shouldFieldBeDisabled()}
                  />
                </div>
              </div>

              {/* Start email template section */}
              <div className='row px-0 mx-0 mt-4'>
                {mountHeaderOfSection('billingEmailTemplateSection')}

                {mountTextEditorWithPreview('email')}
              </div>

              {/* Start sms template section */}
              {/* <div className='row mt-3'>
                {mountHeaderOfSection('billingSMSTemplateSection')}

                <div className='col-md-12 mt-2'>
                  <CheckBoxLabel
                    id='sms'
                    className='checkbox'
                    value={!form?.sms}
                    onChange={(e) => handleFields('sms', !e.target.checked)}
                    checked={form?.sms ? false : true}
                    disabled={shouldFieldBeDisabled()}
                  >
                    {props.intl.messages['billingAlertNotSendSMS']}
                  </CheckBoxLabel>
                </div>
                {mountTextEditorWithPreview('sms')}
              </div> */}
            </div>
            <div className='d-flex flex-column justify-content-end'>
              <Button
                className='button button-primary ms-auto'
                id={'save_button'}
                label={props.intl.messages['saveForm']}
                onClick={onSubmit}
                shouldRenderAlert={isSubmitted}
                success={isSubmitted}
                requestErrors={requestErrors}
                inputErrors={isRequiredFieldsFilled() ? [] : ['disabled']}
                disabled={isSaveDisabled || !form.client_has_email}
              />
            </div>
          </div>
        </div>

        <div className='col-lg-3 mt-5'>
          <Menu {...props} activeCategory={MENU_CATEGORY.PERSONALIZATION} />
        </div>
      </div>

      {isSubmitted && requestErrors === '' && (
        <SuccessModal
          messageKey='dataSavedSuccess'
          onClose={() => setIsSubmitted(false)}
        />
      )}
    </div>
  ) : (
    NotAllowedLayout(props)
  );
};

export default injectIntl(BillingAlertsLayout);
